// Proposals.tsx

import React, { useState, useRef, useEffect } from 'react';
import './style/Proposals.css';
import axiosRequest from '../utils/axiosRequest';
import { Helmet } from 'react-helmet';
import ProposalsLoading from './ProposalsLoading'; // Import the ProposalsLoading component

interface ProposalsProps {
  isDarkMode: boolean;
  setSection: React.Dispatch<React.SetStateAction<string>>;
}

interface EvaluationResponse {
  type: string;
  question: string;
  answer: string;
  sources: any[];
}

const Proposals: React.FC<ProposalsProps> = ({ isDarkMode, setSection }) => {
  // State declarations
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileName, setSelectedFileName] = useState<string>('Choose File');
  const [error, setError] = useState<string | null>(null);
  const [evaluationResults, setEvaluationResults] = useState<EvaluationResponse[]>([]);
  const [documentReady, setDocumentReady] = useState<boolean>(false);
  const [documentId, setDocumentId] = useState<string | null>(null); // New state variable for document ID

  // Refs for accessibility and focus management
  const fileInputRef = useRef<HTMLInputElement>(null);
  const errorRef = useRef<HTMLDivElement>(null);

  // API URL for proposal evaluation
  const proposalsURL = 'https://innoscaleai-backend.com/api/proposal-evaluation/';

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension === 'pdf') {
        setSelectedFile(file);
        setSelectedFileName(file.name);
        setError(null);
      } else {
        setError('Unsupported file format. Please upload a PDF file.');
        setSelectedFile(null);
        setSelectedFileName('Choose File');
      }
    } else {
      setSelectedFile(null);
      setSelectedFileName('Choose File');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setEvaluationResults([]);
    setDocumentReady(false);
    setDocumentId(null); // Reset document ID on new submission

    if (!selectedFile) {
      setError('Please upload a PDF file.');
      return;
    }

    setLoading(true);

    try {
      // Prepare FormData
      const formData = new FormData();
      formData.append('pdfFile', selectedFile);

      // Send POST request to start evaluation
      const response = await axiosRequest(proposalsURL, 'post', formData);
      const { evaluation_id } = response.data;

      // Start streaming evaluation results
      startEventSource(evaluation_id);
    } catch (error: any) {
      setLoading(false);

      if (error.response && error.response.status === 401) {
        setError('Unauthorized. Please log in with a valid email and password.');
      } else {
        setError('Error starting proposal evaluation. Please try again.');
      }

      console.error('Error starting proposal evaluation:', error, 'url used:', proposalsURL);
    }
  };

  const startEventSource = (evaluationId: string) => {
    const eventSourceUrl = `${proposalsURL}?stream=true&evaluation_id=${encodeURIComponent(evaluationId)}`;
    const eventSource = new EventSource(eventSourceUrl);

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.type === 'evaluation') {
        // Append the evaluation result to the state
        setEvaluationResults((prevResults) => {
          // Check if this is the first result
          if (prevResults.length === 0) {
            // First result received, stop loading animation
            setLoading(false);
          }
          return [...prevResults, data];
        });
      } else if (data.type === 'document_ready') {
        // The document is ready; store the document ID
        setDocumentId(data.document_id);
        setDocumentReady(true);
        eventSource.close();
      } else if (data.type === 'error') {
        // Handle error message
        setError(data.message);
        setLoading(false);
        eventSource.close();
      }
    };

    eventSource.onerror = (event) => {
      console.error('EventSource error:', event);
      setError('Error receiving evaluation results. Please try again.');
      setLoading(false);
      eventSource.close();
    };
  };

  const handleDownload = async () => {
    if (!documentId) {
      setError('No document available for download.');
      return;
    }

    try {
      const response = await axiosRequest(
        `${proposalsURL}?document_id=${documentId}`,
        'get',
        null,
        {
          responseType: 'blob',
        }
      );

      // Create a URL for the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Proposal_Evaluation_Report.docx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading document:', error);
      setError('Error downloading document. Please try again.');
    }
  };

  useEffect(() => {
    if (error && errorRef.current) {
      errorRef.current.focus();
    }
  }, [error]);

  return (
    <>
      <Helmet>
        <title>Proposals</title>
      </Helmet>
      <nav aria-label="Primary Navigation" className="primary-nav"></nav>
      <main role="main" tabIndex={-1} className="main-content">
        <div className={`proposals-container ${isDarkMode ? 'dark' : 'light'}`}>
          <h1 tabIndex={0} role="heading" aria-level={1} className={isDarkMode ? 'dark' : 'light'}>
            Proposal Evaluator
          </h1>
          <p tabIndex={0} role="note" className={isDarkMode ? 'dark' : 'light'}>
            A multi-agent AI system that evaluates proposals based on NAWCTSD criteria 
          </p>
          <form className="proposals-form" onSubmit={handleSubmit}>
            <div className="proposals-input-container">
              <label htmlFor="proposals-query" className="visually-hidden">
                Search Query
              </label>
              <input
                id="proposals-query"
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Describe Project Objective"
                autoComplete="on"
                aria-describedby="error-message"
                className={`${isDarkMode ? 'dark' : 'light'}`}
              />

              <div className="proposals-buttons-container">
                {/* File Input Button with Tooltip */}
                <div className="proposals-button-with-tooltip">
                  <button
                    type="button"
                    className={`proposals-file-input-button ${isDarkMode ? 'dark' : 'light'}`}
                    onClick={() => fileInputRef.current?.click()}
                    aria-describedby="file-input-tooltip"
                  >
                    &#9776; {selectedFileName}
                  </button>
                  <span id="file-input-tooltip" className="proposals-tooltip-text">
                    Upload a PDF or DOCX file from your computer to be used as document context
                  </span>
                  {/* Hidden file input */}
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    accept=".pdf,.docx"
                  />
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  className={`proposals-submit-button ${isDarkMode ? 'dark' : 'light'}`}
                  aria-label="Submit Query"
                >
                  &#x27A4;
                </button>
              </div>
            </div>

            {/* Error Message */}
            {error && (
              <div
                id="error-message"
                className={`proposals-error ${error ? 'visible' : 'hidden'} ${
                  isDarkMode ? 'dark' : 'light'
                }`}
                aria-live="assertive"
                role="alert"
                ref={errorRef}
                tabIndex={-1}
              >
                {error}
              </div>
            )}
          </form>

          {loading && <ProposalsLoading isDarkMode={isDarkMode} />}

          {documentReady && (
            <div className="proposals-report-generated-header">
              <h2
                className={`proposals-report-generated-header ${isDarkMode ? 'dark' : 'light'}`}
                tabIndex={0}
                role="heading"
                aria-level={2}
              >
                Report Generated!
              </h2>
              <div className="proposals-report-generated-button">
                <button
                  onClick={handleDownload}
                  className={`proposals-download-button ${isDarkMode ? 'dark' : 'light'}`}
                >
                  Download Report
                </button>
              </div>
            </div>
          )}

          {evaluationResults.length > 0 && (
            <div className={`proposals-response ${isDarkMode ? 'dark' : 'light'}`}>
              <h2
                tabIndex={0}
                role="heading"
                aria-level={2}
                className={isDarkMode ? 'dark' : 'light'}
              >
                Evaluation Results
              </h2>
              <div className="proposals-results-container">
                {evaluationResults.map((result: EvaluationResponse, index: number) => (
                  <div
                    key={index}
                    className={`proposals-result-card ${isDarkMode ? 'dark' : 'light'}`}
                  >
                    <h3
                      id={`result-heading-${index}`}
                      tabIndex={0}
                      role="heading"
                      aria-level={3}
                      className={isDarkMode ? 'dark' : 'light'}
                    >
                      {index + 1}: {result.question}
                    </h3>
                    <p tabIndex={0} role="document" className={isDarkMode ? 'dark' : 'light'}>
                      {result.answer}
                    </p>
                    {/* Optionally display sources */}
                    {result.sources && result.sources.length > 0 && (
                      <details>
                        <summary>Sources</summary>
                        {result.sources.map((source, idx) => (
                          <p key={idx}>
                            Page {source.page}: {source.content}
                          </p>
                        ))}
                      </details>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default Proposals;

import React from 'react';
import './style/SiteMap.css';

interface SiteMapProps {
  setSection: (section: string) => void;
  isInProposalsGroup: boolean;
  isDarkMode: boolean; // Add this prop to control the mode
}

const SiteMap: React.FC<SiteMapProps> = ({ setSection, isInProposalsGroup, isDarkMode }) => {
  return (
    <div className={`site-map ${isDarkMode ? 'dark' : 'light'}`}>
      <ul>
        {isInProposalsGroup ? (
          <>
            <li>
              <button onClick={() => setSection('Proposals')}>Proposal Evaluator</button>
            </li>
            <li>
              <button onClick={() => setSection('Documents')}>Library</button>
            </li>
          </>
        ) : (
          <>
            <li>
              <button onClick={() => setSection('TechScouting')}>Tech Scouting</button>
            </li>
            <li>
              <button onClick={() => setSection('StakeholderIdentification')}>Stakeholder Identification</button>
            </li>
            <li>
              <button onClick={() => setSection('ProjectSupport')}>Project Support</button>
            </li>
            <li>
              <button onClick={() => setSection('MOUGeneration')}>MOU Generation</button>
            </li>
            <li>
              <button onClick={() => setSection('Documents')}>Library</button>
            </li>
            <li>
              <button onClick={() => setSection('ATOAssistant')}>ATO Assistant</button>
            </li>
            <li>
              <button onClick={() => setSection('Proposals')}>Proposal Evaluator</button>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default SiteMap;

import React, { useState, useEffect } from 'react';
import axiosRequest from '../utils/axiosRequest';
import './style/ATODocuments.css';
import { Helmet } from 'react-helmet';
import { Document } from '../types';

interface ATODocumentsProps {
    setSection: (section: string) => void;
    isDarkMode: boolean;  // Add dark mode prop
}

const ATODocuments: React.FC<ATODocumentsProps> = ({ setSection, isDarkMode }) => {
    const [documents, setDocuments] = useState<Document[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const documentsURL = process.env.REACT_APP_DOCUMENTS_API_URL;

    if (!documentsURL) {
        throw new Error('API URLs are not defined');
    }

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const response = await axiosRequest(`${documentsURL}ato`, 'get');

                // Sort documents alphabetically by title
                const sortedDocuments = response.data.sort((a: Document, b: Document) => {
                    return a.documentTitle.localeCompare(b.documentTitle);
                });

                setDocuments(sortedDocuments);
            } catch (err: any) {
                if (err.response && err.response.status === 401) {
                    setError('Unauthorized. Please log in with a valid email and password.');
                } else {
                    setError('Error fetching documents');
                }
            }
        };
        fetchDocuments();
    }, [documentsURL]);

    const handleView = async (documentId: string, documentTitle: string) => {
        if (!documentId || !documentTitle) {
            setError('Invalid document data.');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await axiosRequest(`${documentsURL}ato/${documentTitle}/download/`, 'get');

            if (!response || !response.data || !response.data.url) {
                throw new Error('View URL not found or document does not exist.');
            }

            const url = response.data.url;

            // Open the document in a new window instead of downloading it
            window.open(url, '_blank');

            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            if (err.response && err.response.status === 401) {
                setError('Unauthorized. Please log in with a valid email and password.');
            } else {
                setError('Error opening document');
            }
        }
    };

    const formatDateTime = (dateString: string) => {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        };
        const formattedDate = date.toLocaleDateString(undefined, options);
        const formattedTime = date.toLocaleTimeString(undefined, { hour12: false });
        return `${formattedDate} ${formattedTime}`;
    };

    return (
        <>
            <Helmet>
                <title>ATO Documents</title>
            </Helmet>
            <nav aria-label="Primary Navigation" className="primary-nav">
                {/* Navigation items can be added here if necessary */}
            </nav>
            <main role="main" tabIndex={-1} className="main-content">
                <div className={`ato-documents-container ${isDarkMode ? 'dark' : 'light'}`}>
                    <div className="ato-documents-header-container">
                        <div className="ato-documents-header-title-container">
                            <h1 tabIndex={0} role="heading" aria-level={1} className={isDarkMode ? 'dark' : 'light'}>
                                ATO Documents
                            </h1>
                        </div>

                        <div className="ato-documents-link-buttons-container">
                            <button
                                onClick={() => setSection('Documents')}
                                className={`ato-documents-link-button ${isDarkMode ? 'dark' : 'light'}`}
                                aria-label="User Library - View and manage user documents"
                            >
                                User Library
                                <span className="ato-documents-tooltip-text">View and manage user documents</span>
                            </button>
                            <button
                                onClick={() => setSection('ATOAssistant')}
                                className={`ato-documents-link-button ${isDarkMode ? 'dark' : 'light'}`}
                                aria-label="Assistant - Access the ATO Assistant"
                            >
                                Assistant
                                <span className="ato-documents-tooltip-text">Access the ATO Assistant</span>
                            </button>
                        </div>

                        <p tabIndex={0} role="note" className={isDarkMode ? 'dark' : 'light'}>
                            List of saved ATO documents.
                        </p>
                    </div>
                    {error && <p className="ato-documents-error-message" role="alert">{error}</p>}
                    {loading && <p>Loading...</p>}
                    <table className="ato-documents-table" role="table" aria-label="Documents table">
                        <thead>
                            <tr role="row">
                                <th tabIndex={0} role="columnheader" className={isDarkMode ? 'dark' : 'light'}>
                                    Name
                                </th>
                                <th tabIndex={0} role="columnheader" className={isDarkMode ? 'dark' : 'light'}>
                                    Type
                                </th>
                                <th tabIndex={0} role="columnheader" className={isDarkMode ? 'dark' : 'light'}>
                                    Created At
                                </th>
                                <th tabIndex={0} role="columnheader" className={isDarkMode ? 'dark' : 'light'}>
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {documents.length === 0 ? (
                                <tr>
                                    <td className="ato-documents-no-documents-message" colSpan={4}>
                                        No documents found.
                                    </td>
                                </tr>
                            ) : (
                                documents.map((document) => (
                                    <tr key={document.id} role="row">
                                        <td data-label="Name" tabIndex={0} role="cell">
                                            {document.documentTitle}
                                        </td>
                                        <td data-label="Type" tabIndex={0} role="cell">{document.documentType}</td>
                                        <td data-label="Created At" tabIndex={0} role="cell">{formatDateTime(document.created_at)}</td>
                                        <td data-label="Actions" role="cell">
                                            <button
                                                aria-label={`View document ${document.documentTitle}`}
                                                onClick={() => handleView(document.id, document.documentTitle)}
                                                className="ato-documents-text-button"
                                                tabIndex={0}
                                            >
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </main>
        </>
    );
};

export default ATODocuments;

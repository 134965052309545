// Settings.tsx

import React, { useState, useEffect, useRef } from 'react';
import './style/Settings.css';
import axiosRequest from '../utils/axiosRequest';
import { useDefaultProjectContext } from '../ContextStore';
import { Project } from '../types';

interface SettingsProps {
  userEmail: string;
  handleSignOut: () => void;
  isDarkMode: boolean;
  toggleDarkMode: () => void;
}

const Settings: React.FC<SettingsProps> = ({
  userEmail,
  handleSignOut,
  isDarkMode,
  toggleDarkMode,
}) => {
  // **Consume DefaultProjectContext using the custom hook**
  const { defaultProjectName, setDefaultProjectName, setDefaultProjectId } = useDefaultProjectContext();

  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProjectId, setSelectedProjectId] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const projectsURL = process.env.REACT_APP_PROJECTS_API_URL;
  if (!projectsURL) {
    throw new Error('REACT_APP_PROJECTS_API_URL is not defined');
  }

  // **Fetch Projects on Component Mount**
  useEffect(() => {
    const fetchProjects = async () => {
      setError(null);
      try {
        const response = await axiosRequest(`${projectsURL}`, 'get');
        setProjects(response.data);

        const defaultProject = response.data.find((project: Project) => project.is_default);
        if (defaultProject) {
          setSelectedProjectId(defaultProject.id);
          setDefaultProjectName(defaultProject.name);
          setDefaultProjectId(defaultProject.id);
        }
      } catch (err: any) {
        console.error('Error fetching projects:', err);
        setError('Failed to fetch projects.');
      }
    };

    fetchProjects();
  }, [projectsURL, setDefaultProjectId, setDefaultProjectName]);

  // **Handle Project Selection**
  const handleProjectSelect = async (projectId: string) => {
    setSelectedProjectId(projectId);
    setDefaultProjectId(projectId);
    setError(null);
    setIsDropdownOpen(false);
    try {
      const apiUrl = `${projectsURL}${projectId}/update/`;

      // Update the selected project to be the default
      await axiosRequest(apiUrl, 'patch', { is_default: true });

      // Update defaultProjectName in context
      const selectedProject = projects.find((project) => project.id === projectId);
      if (selectedProject) {
        setDefaultProjectName(selectedProject.name);
      }
    } catch (err: any) {
      console.error('Error updating default project:', err);
      setError('Failed to set default project.');
    }
  };

  // **Close Dropdown when Clicking Outside**
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div className={`settings-page ${isDarkMode ? 'dark' : 'light'}`}>
      <nav aria-label="Primary Navigation" className="primary-nav"></nav>
      <main role="main" tabIndex={-1} className="main-content">
      <h1 className="settings-title">Settings</h1>
      <div className="settings-underline"></div>
      <div className="settings-card">
        <h2 className="settings-section-title">General</h2>

        <div className="settings-item">
          <p>
            You are signed in as: <span>{userEmail}</span>
          </p>
          <button
            className={`settings-signout-button ${isDarkMode ? 'dark' : 'light'}`}
            onClick={handleSignOut}
          >
            Sign Out
          </button>
        </div>

        <div className="settings-item">
          <span className="settings-label">Appearance</span>
          <div className="settings-appearance-toggle-container">
            <button
              onClick={toggleDarkMode}
              className={`settings-toggle-button ${isDarkMode ? 'dark' : 'light'}`}
            >
              {isDarkMode ? 'Dark Mode' : 'Light Mode'}
            </button>
          </div>
        </div>

        {/* **New Section: Set Active Project** */}
        <div className="settings-item">
          <span className="settings-label">Set Active Project</span>
          <div className="settings-active-project-container" ref={dropdownRef}>
            <button
              className={`settings-project-button ${isDarkMode ? 'dark' : 'light'}`}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              aria-haspopup="listbox"
              aria-expanded={isDropdownOpen}
            >
              {defaultProjectName || 'Select a project'}
            </button>
            {isDropdownOpen && (
              <div className={`settings-project-dropdown ${isDarkMode ? 'dark' : 'light'}`} role="listbox">
                {projects.map((project) => (
                  <button
                    key={project.id}
                    className={`settings-project-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                    onClick={() => handleProjectSelect(project.id)}
                    role="option"
                    aria-selected={selectedProjectId === project.id}
                  >
                    {project.name}
                  </button>
                ))}
              </div>
            )}
            {error && <span className="settings-project-error" role="alert">{error}</span>}
          </div>
        </div>
      </div>
      </main>
    </div>
  );
};

export default Settings;

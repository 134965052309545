// LibraryModal.tsx

import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './style/LibraryModal.css'; // Adjust the path as necessary

interface LibraryModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

const LibraryModal: React.FC<LibraryModalProps> = ({ isOpen, onClose, title, children }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  // Close modal on ESC key press
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, [isOpen, onClose]);

  // Close modal when clicking outside the modal content
  const handleClickOutside = (e: React.MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className="library-modal-overlay"
      onClick={handleClickOutside}
      aria-labelledby="library-modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="library-modal-content" ref={modalRef}>
        <button className="library-modal-close-button" onClick={onClose} aria-label="Close Modal">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 id="library-modal-title">{title}</h2>
        <div className="library-modal-body">
          {children}
        </div>
      </div>
    </div>
  );
};

export default LibraryModal;

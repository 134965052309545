// axiosRequest.ts

import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

const axiosRequest = async (
  url: string,
  method: 'get' | 'post' | 'put' | 'patch' | 'delete',
  data?: any,
  configOverrides?: AxiosRequestConfig
) => {
  const csrfToken = Cookies.get('csrftoken');

  const config: AxiosRequestConfig = {
    method: method,
    url: url,
    withCredentials: true, // Include cookies
    headers: {
      'X-CSRFToken': csrfToken || '', // Include CSRF token if needed
    },
    data: data,
    ...configOverrides, // Allow overrides like responseType
  };

  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    throw error;
  }
};

export default axiosRequest;

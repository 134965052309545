// SignUp.tsx
import React, { useState } from 'react';
import axiosRequest from '../utils/axiosRequest';
import './style/SignUp.css';
import { Helmet } from 'react-helmet';

interface SignUpProps {
  onSignIn: () => void;
  setSection: (section: string) => void; // Add this to handle navigation
}

const SignUp: React.FC<SignUpProps> = ({ onSignIn, setSection }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isRegistered, setIsRegistered] = useState(false); // Track if user has signed up
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [session, setSession] = useState<string | null>(null); // Session for password challenge
  const [resendSuccessMessage, setResendSuccessMessage] = useState(''); // Added for resend confirmation code

  const signupURL = process.env.REACT_APP_SIGNIN_API_URL;

  if (!signupURL) {
    throw new Error('REACT_APP_SIGNIN_API_URL is not defined');
  }

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await axiosRequest(`${signupURL}register/`, 'post', {
        email,
        password,
      });

      if (response.status === 201) {
        setSuccessMessage('Registration successful. Please check your email for the confirmation code.');
        setIsRegistered(true); // Prompt user to input confirmation code
      }
    } catch (err: any) {
      setError(err.response?.data?.error || 'Sign-up failed');
    }
  };

  const handleConfirmSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    setResendSuccessMessage('');

    try {
      // Verify email using the custom backend route
      const response = await axiosRequest(`${signupURL}verify-email/`, 'post', {
        email,
        confirmationCode,
      });

      if (response.status === 200) {
        setSuccessMessage('Account confirmed successfully.');

        // Automatically sign in the user after confirmation using backend
        const signInResponse = await axiosRequest(signupURL, 'post', {
          email,
          password,
        });

        if (signInResponse.data.challenge === 'NEW_PASSWORD_REQUIRED') {
          setSession(signInResponse.data.session);
          setError('New password required');
        } else {
          // Tokens are stored in cookies by the backend
          // Call onSignIn to update the authentication state
          onSignIn();

          // Redirect to MOUGeneration component
          setSection('MOUGeneration');
        }
      }
    } catch (err: any) {
      setError(err.response?.data?.error || 'Confirmation or Sign-in failed. Please try again.');
    }
  };

  // Added function to handle resending the confirmation code
  const handleResendConfirmationCode = async () => {
    setError('');
    setResendSuccessMessage('');

    try {
      const response = await axiosRequest(`${signupURL}resend-code/`, 'post', {
        email,
      });

      if (response.status === 200) {
        setResendSuccessMessage('Confirmation code resent successfully. Please check your email.');
      }
    } catch (err: any) {
      setError(err.response?.data?.error || 'Failed to resend confirmation code.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <a href="#main-content" className="skip-link">
        Skip to main content
      </a>
      <nav aria-label="Primary Navigation" className="primary-nav">
        {/* Navigation items go here */}
      </nav>
      <div className="container">
        <div className="sign-in-form">
          <img src="/InnoScaleLogo1Cropped.png" alt="InnoScale company logo" />
          <main id="main-content" role="main" tabIndex={-1} className="main-content">
            <h1 className="sr-only">Sign Up</h1>
            {!isRegistered ? (
              <form onSubmit={handleSignUp}>
                <label htmlFor="email">Email:</label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  autoComplete="email"
                />
                <label htmlFor="password">Password:</label>
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  autoComplete="new-password"
                />
                <label htmlFor="confirm-password">Confirm Password:</label>
                <input
                  id="confirm-password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  autoComplete="new-password"
                />
                <button type="submit">Sign Up</button>
              </form>
            ) : (
              <div>
                <p className="confirmation-message">
                  Please enter the confirmation code sent to {email}.
                </p>
                <form onSubmit={handleConfirmSignUp}>
                  <label htmlFor="confirmationCode">Confirmation Code:</label>
                  <input
                    id="confirmationCode"
                    type="text"
                    value={confirmationCode}
                    onChange={(e) => setConfirmationCode(e.target.value)}
                    required
                  />
                  <button type="submit">Confirm Sign Up</button>
                </form>
                {/* Add Resend Confirmation Code button */}
                <button onClick={handleResendConfirmationCode} className="resend-button">
                  Resend Confirmation Code
                </button>
              </div>
            )}
            {error && <p className="error-message">{error}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}
            {resendSuccessMessage && <p className="success-message">{resendSuccessMessage}</p>}
          </main>
        </div>
      </div>
    </>
  );
};

export default SignUp;

import React, { useState, useEffect, useRef } from 'react';
import axiosRequest from '../utils/axiosRequest';
import './style/ATOAssistant.css';
import Loading from './Loading'; // Import the loading component
import { Helmet } from 'react-helmet';
import { Message } from '../types';

interface ATOAssistantProps {
  setSection: (section: string) => void;
  isDarkMode: boolean; // Add isDarkMode prop
}

const ATOAssistant: React.FC<ATOAssistantProps> = ({ setSection, isDarkMode }) => {
  const [query, setQuery] = useState<string>('');
  const [conversation, setConversation] = useState<Message[]>([]);
  const [threadId, setThreadId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [errorKey, setErrorKey] = useState<number>(0);
  const [retryAttempt, setRetryAttempt] = useState<boolean>(false);
  const [currentMessage, setCurrentMessage] = useState<string>('');
  const [tempToken, setTempToken] = useState<string | null>(null); // New state variable for temp_token
  const documentsURL = process.env.REACT_APP_DOCUMENTS_API_URL;
  const ATOAssistURL = process.env.REACT_APP_ATO_ASSIST_API_URL;
  const errorRef = useRef<HTMLDivElement>(null);

  if (!ATOAssistURL || !documentsURL) {
    throw new Error('REACT_APP_ATO_ASSIST_API_URL or REACT_APP_DOCUMENTS_API_URL is not defined');
  }

  const parseEventString = (data: string) => {
    try {
      const eventTypeMatch = data.match(/(\w+)\(data=(.*)\)/);
      if (eventTypeMatch) {
        const eventType = eventTypeMatch[1];
        const dataString = eventTypeMatch[2];

        const deltaMatch = dataString.match(/delta=MessageDelta\(content=\[TextDeltaBlock\(index=\d+, type='text', text=TextDelta\(annotations=.*?, value='(.*?)'\)\)\], role=None\)/);
        if (deltaMatch) {
          return {
            event: 'thread.message.delta',
            data: {
              delta: {
                content: [{ text: { value: deltaMatch[1] } }]
              }
            }
          };
        }
      }
      return null;
    } catch (error) {
      console.error('Failed to parse event string:', error);
      return null;
    }
  };

  const handleAIStreaming = async (
    inputQuery: string,
    isFollowUp: boolean = false,
    documentTitle: string | null = null,
    retry: boolean = false
  ) => {
    setLoading(true);
    setError(null);

    if (!inputQuery.trim()) {
      setErrorKey((prevKey) => prevKey + 1);
      setError('User input empty. Please input a query.');
      setLoading(false);
      return;
    }

    if (!isFollowUp) {
      setConversation((prev) => [...prev, { role: 'user', content: inputQuery }]);
    }

    setCurrentMessage('');

    try {
      // Always make the POST request to get a new temp_token (JWT) and thread_id
      const { data } = await axiosRequest(ATOAssistURL, 'post', {
        query: inputQuery,
        thread_id: threadId,
      });

      // Update threadId from the response
      setThreadId(data.thread_id);

      // Get the temp_token (JWT)
      const tempToken = data.temp_token;

      // Construct the EventSource URL with temp_token (JWT)
      const eventSourceUrl = `${ATOAssistURL}?query=${encodeURIComponent(
        inputQuery
      )}&thread_id=${encodeURIComponent(data.thread_id)}&temp_token=${encodeURIComponent(
        tempToken
      )}`;

      const eventSource = new EventSource(eventSourceUrl);

      let isInBold = false;
      let completed = false;

      eventSource.onmessage = (event) => {
        if (event.data.includes('thread.run.completed')) {
          setLoading(false);
          completed = true;
          eventSource.close();
          return;
        }

        const parsedData = parseEventString(event.data);
        if (parsedData && parsedData.event === 'thread.message.delta') {
          let textDelta = parsedData.data?.delta?.content[0]?.text?.value;

          if (textDelta) {
            textDelta = textDelta.replace(/#/g, '');
            let processedText = textDelta.replace(/\*\*/g, () => {
              isInBold = !isInBold;
              return isInBold ? '<strong>' : '</strong>';
            });
            processedText = processedText.replace(/\\n/g, '<br>').replace(/\n/g, '<br>');

            // Replace document references with clickable links
            const documentLinkRegex = /\[(.*?)\]/g;
            const replacedText = processedText.replace(
              documentLinkRegex,
              (match, docTitle) => {
                return `[<a href="#" class="ato-assistant-document-link" data-title="${docTitle}">${docTitle}</a>]`;
              }
            );

            setCurrentMessage((prev) => prev + replacedText);

            setConversation((prev) => {
              const lastMessageIndex = prev.length - 1;
              const lastMessage = prev[lastMessageIndex];

              if (lastMessage && lastMessage.role === 'assistant') {
                const updatedMessages = [...prev];
                updatedMessages[lastMessageIndex] = {
                  ...lastMessage,
                  content: prev[lastMessageIndex].content + replacedText,
                };
                return updatedMessages;
              } else {
                return [...prev, { role: 'assistant', content: replacedText }];
              }
            });
          }
        }
      };

      eventSource.onerror = async (event) => {
        console.error('EventSource error:', event);
        eventSource.close();
        if (!retryAttempt && !retry) {
          setRetryAttempt(true);
          console.warn('Retrying AI streaming...');
          await handleAIStreaming(inputQuery, isFollowUp, documentTitle, true);
        } else {
          setLoading(false);
          setError('An error occurred while streaming data.');
          setRetryAttempt(false);
        }
      };
    } catch (error: any) {
      setLoading(false);
      setErrorKey((prevKey) => prevKey + 1);
      if (!retryAttempt && !retry) {
        setRetryAttempt(true);
        console.warn('Retrying AI streaming after catch error...');
        await handleAIStreaming(inputQuery, isFollowUp, documentTitle, true);
      } else {
        if (error.response && error.response.status === 401) {
          setError('Unauthorized. Please log in with a valid email and password.');
        } else if (error instanceof Error) {
          setError(`An error occurred while initiating the request: ${error.message}`);
        } else {
          setError('An unexpected error occurred.');
        }
        setRetryAttempt(false);
      }
    }
  };


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleAIStreaming(query);
  };

  const handleDocumentClick = async (e: Event) => {
    e.preventDefault();

    const target = e.currentTarget as HTMLAnchorElement;
    const documentTitle = target.getAttribute('data-title');

    if (documentTitle) {
      try {
        const response = await axiosRequest(`${documentsURL}ato/${documentTitle}/download/`, 'get');
        if (!response.data.url) throw new Error('Document URL not found');
        window.open(response.data.url, '_blank');
      } catch (err: any) {
        setError('Error retrieving document reference information');
        console.error('Error opening document:', err);
      }

      const followUpQuery = `Where in the ${documentTitle} specifically did you find the information that gave you your last response? Give me the exact text from the document.`;

      setConversation((prev) => [
        ...prev,
        { role: 'assistant', content: '[Retrieving document reference information]' }
      ]);

      handleAIStreaming(followUpQuery, true, documentTitle);
    }
  };

  useEffect(() => {
    const links = document.querySelectorAll('.ato-assistant-document-link');
    links.forEach(link => {
      link.addEventListener('click', handleDocumentClick);
    });

    return () => {
      links.forEach(link => {
        link.removeEventListener('click', handleDocumentClick);
      });
    };
  }, [currentMessage, conversation]);

  useEffect(() => {
    if (error && errorRef.current) {
      errorRef.current.focus();
    }
  }, [error]);

  return (
    <>
      <Helmet>
        <title>ATO Assistant</title>
      </Helmet>
      <nav aria-label="Primary Navigation" className="primary-nav"></nav>
      <main role="main" tabIndex={-1} className="main-content">
        <div className={`ato-assistant-container ${isDarkMode ? 'dark' : 'light'}`}>
          <div className="ato-assistant-header-and-button-container">
            <h1 tabIndex={0} role="heading" aria-level={1} className={isDarkMode ? 'dark' : 'light'}>
              ATO Assistant
            </h1>
            <button
              onClick={() => setSection('ATODocuments')}
              className={`ato-assistant-docs-button ${isDarkMode ? 'dark' : 'light'}`}
              aria-label="ATO Docs - View and manage ATO documents"
            >
              ATO Docs
              <span className="ato-assistant-tooltip-text">View and manage ATO documents</span>
            </button>
          </div>
          <p tabIndex={0} role="note" className={isDarkMode ? 'dark' : 'light'}>
            AI chatbot that answers questions about the ATO Process.
          </p>
          <form className="ato-assistant-form" onSubmit={handleSubmit}>
            <div className="ato-assistant-input-container">
              <label htmlFor="ato-assistant-query" className="visually-hidden">Search Query</label>
              <input
                id="ato-assistant-query"
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Ex: What is the first step in the ATO process?"
                autoComplete="on"
                aria-describedby="error-message"
                className={isDarkMode ? 'dark' : 'light'}
              />
              <button type="submit" className={`ato-assistant-submit-button ${isDarkMode ? 'dark' : 'light'}`} aria-label="Submit Query">
                &#x27A4;
              </button>
            </div>
            <div
              id="error-message"
              key={errorKey}
              className={`ato-assistant-error ${error ? 'visible' : 'hidden'} ${isDarkMode ? 'dark' : 'light'}`}
              aria-live="assertive"
              role="alert"
              ref={errorRef}
            >
              {error}
            </div>
          </form>
          {loading && <Loading />}
          {conversation.length > 0 && (
            <div className="ato-assistant-response">
              <h2 tabIndex={0} role="heading" aria-level={2}>Conversation:</h2>
              <div className="ato-assistant-conversation-container">
                {conversation
                  .slice()
                  .reverse()
                  .map((message, index) => (
                    <div
                      key={index}
                      className={`ato-assistant-message ${message.role} ${isDarkMode ? 'dark' : 'light'}`}
                      role="region"
                      aria-labelledby={`message-${index}`}
                      tabIndex={0}
                      aria-describedby={`message-content-${index}`}
                    >
                      <h3 id={`message-${index}`} className="sr-only">
                        {message.role === 'user' ? 'User' : 'Assistant'} Message {index + 1}
                      </h3>
                      <p id={`message-content-${index}`} dangerouslySetInnerHTML={{ __html: message.content }} />
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default ATOAssistant;

// ContextStore.tsx

import React, { createContext, useContext } from 'react';
import { StakeholderData, CompanyData } from './types';

interface TechScoutingContextType {
  techResponse: any;
  setTechResponse: React.Dispatch<React.SetStateAction<any>>;
  selectedCompany: CompanyData | null;
  setSelectedCompany: React.Dispatch<React.SetStateAction<CompanyData | null>>;
}

interface StakeholderIdentificationContextType {
  stakeholderResponse: StakeholderData[];
  setStakeholderResponse: React.Dispatch<React.SetStateAction<StakeholderData[]>>;
}

interface DefaultProjectContextType {
  defaultProjectId: string;
  setDefaultProjectId: React.Dispatch<React.SetStateAction<string>>;
  defaultProjectName: string;
  setDefaultProjectName: React.Dispatch<React.SetStateAction<string>>;
}

export const TechScoutingContext = createContext<TechScoutingContextType | undefined>(undefined);
export const StakeholderIdentificationContext = createContext<StakeholderIdentificationContextType | undefined>(undefined);
export const DefaultProjectContext = React.createContext<DefaultProjectContextType | null>(null);

// **Custom Hook for DefaultProjectContext**
export const useDefaultProjectContext = (): DefaultProjectContextType => {
  const context = useContext(DefaultProjectContext);
  if (!context) {
    throw new Error('useDefaultProjectContext must be used within a DefaultProjectContext.Provider');
  }
  return context;
};

// pusherConfig.ts

import Pusher, { Options } from 'pusher-js';
import Cookies from 'js-cookie';

const PUSHER_CONFIG = (process.env.REACT_APP_PUSHER_CONFIG)?.split(',');

if (!PUSHER_CONFIG) {
  throw new Error('PUSHER_CONFIG is invalid or missing');
}

export const createPusherInstance = () => {
  const pusherKey = PUSHER_CONFIG[1];
  const pusherCluster = PUSHER_CONFIG[3];
  const pusherURL = PUSHER_CONFIG[4];

  // Get the idToken from cookies
  const idToken = Cookies.get('idToken');

  const options: Options = {
    cluster: pusherCluster,
    // Define a custom authorizer
    authorizer: (channel, pusher) => {
      return {
        authorize: (socketId, callback) => {
          const xhr = new XMLHttpRequest();
          xhr.open('POST', pusherURL, true);
          xhr.setRequestHeader('Content-Type', 'application/json');
          xhr.withCredentials = true; // Include cookies
          // Optionally, include the idToken in headers if your backend expects it
          xhr.setRequestHeader('Authorization', `Bearer ${idToken}`);
          xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                const data = JSON.parse(xhr.responseText);
                callback(null, data);
              } else {
                callback(new Error(xhr.status.toString()), null);
              }
            }
          };
          xhr.send(
            JSON.stringify({
              socket_id: socketId,
              channel_name: channel.name,
            })
          );
        },
      };
    },
  };

  return new Pusher(pusherKey, options);
};

// App.tsx

import React, { useState, useEffect, useRef } from 'react';
import Navbar from './components/Navbar';
import TechScouting from './components/TechScouting';
import StakeholderIdentification from './components/StakeholderIdentification';
import ProjectSupport from './components/ProjectSupport';
import SignIn from './components/SignIn';
import MOUGeneration from './components/MOUGeneration';
import SiteMap from './components/SiteMap';
import Library from './components/Library';
import ATODocuments from './components/ATODocuments';
import ATOAssistant from './components/ATOAssistant';
import SignUp from './components/SignUp';
import Settings from './components/Settings';
import CompanyDetails from './components/CompanyDetails';
import Proposals from './components/Proposals';
import {
  TechScoutingContext,
  StakeholderIdentificationContext,
  DefaultProjectContext,
} from './ContextStore';
import { StakeholderData, CompanyData } from './types';
import { createPusherInstance } from './utils/pusherConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pusher, { Channel } from 'pusher-js';
import axiosRequest from './utils/axiosRequest';
import './App.css';

const App: React.FC = () => {
  const [section, setSection] = useState<string>('TechScouting');
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isInProposalsGroup, setIsInProposalsGroup] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [techResponse, setTechResponse] = useState<any>(null);
  const [selectedCompany, setSelectedCompany] = useState<CompanyData | null>(null);
  const [stakeholderResponse, setStakeholderResponse] = useState<StakeholderData[]>([]);

  // Use refs to manage Pusher instance and channel
  const pusherRef = useRef<Pusher | null>(null);
  const channelRef = useRef<Channel | null>(null);

  // New State for Default Project
  const [defaultProjectName, setDefaultProjectName] = useState<string>('');
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
  const [defaultProjectId, setDefaultProjectId] = useState<string>('');

  // State for dark mode
  const [isDarkMode, setIsDarkMode] = useState<boolean>(
    () => localStorage.getItem('darkMode') !== 'false'
  );

  const authURL = process.env.REACT_APP_SIGNIN_API_URL;
  if (!authURL) {
    throw new Error('REACT_APP_SIGNIN_API_URL is not defined');
  }

  const projectsURL = process.env.REACT_APP_PROJECTS_API_URL;
  if (!projectsURL) {
    throw new Error('REACT_APP_PROJECTS_API_URL is not defined');
  }

  // Effect to initialize dark mode from localStorage
  useEffect(() => {
    const theme = isDarkMode ? 'dark-mode' : 'light-mode';
    document.body.classList.add(theme);

    return () => {
      document.body.classList.remove('dark-mode', 'light-mode');
    };
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    localStorage.setItem('darkMode', newMode ? 'true' : 'false');

    if (newMode) {
      document.body.classList.remove('light-mode');
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
      document.body.classList.add('light-mode');
    }
  };

  // Function to initialize Pusher
  const initializePusher = () => {
    if (pusherRef.current) {
      console.log('Pusher already initialized.');
      return;
    }

    if (!userId) {
      console.error('User ID not found for Pusher.');
      return;
    }

    const pusher = createPusherInstance();
    pusherRef.current = pusher;

    console.log(`Subscribing to private channel for user ${userId}`);
    const channel = pusher.subscribe(`private-user-${userId}`);
    channelRef.current = channel;

    channel.bind('document-event', handleDocumentEvent);
    channel.bind('pusher:subscription_error', handleSubscriptionError);

    console.log('Pusher subscription initialized.');
  };

  const handleDocumentEvent = (data: any) => {
    console.log('Received document event:', data);
    if (data.message === 'document_complete') {
      const { document_url } = data;
      toast.success('Generated Document added to Library!', {
        position: 'top-right',
        autoClose: 5000,
        theme: 'dark',
        icon: (
          <img
            src="/LogoThumbnailWhite.png"
            alt="Logo Thumbnail"
            className="toast-custom-icon"
          />
        ),
        onClick: () => window.open(document_url, '_blank'),
        className: 'custom-toast-clickable',
      });
    }
  };

  const handleSubscriptionError = (status: number) => {
    console.error('Pusher subscription error:', status);
  };

  // Cleanup Pusher on unmount or when signing out
  const cleanupPusher = () => {
    if (channelRef.current) {
      console.log('Cleaning up Pusher channel subscription');
      channelRef.current.unbind_all();
      channelRef.current.unsubscribe();
      channelRef.current = null;
    }
    if (pusherRef.current) {
      console.log('Disconnecting Pusher instance');
      pusherRef.current.disconnect();
      pusherRef.current = null;
    }
  };

  // Function to fetch user info
  const fetchUserInfo = async () => {
    try {
      const response = await axiosRequest(`${authURL}userinfo/`, 'get');
      const { email, groups, user_id } = response.data;
      setUserEmail(email);
      setUserId(user_id);
      setIsInProposalsGroup(Array.isArray(groups) && groups.includes('proposals'));
      await fetchDefaultProject(); // Fetch default project after user info
    } catch (error) {
      console.error('Error fetching user info:', error);
      setIsAuthenticated(false);
    }
  };

  // Function to fetch the default project name
  const fetchDefaultProject = async () => {
    try {
      const response = await axiosRequest(`${projectsURL}`, 'get');
      const defaultProject = response.data.find((project: any) => project.is_default);
      if (defaultProject) {
        setDefaultProjectName(defaultProject.name);
        setDefaultProjectId(defaultProject.id); // Store the default project ID
      }
    } catch (error) {
      console.error('Error fetching default project:', error);
    }
  };

  // Fetch user info from backend when authenticated changes
  useEffect(() => {
    if (isAuthenticated) {
      fetchUserInfo();
    }
  }, [isAuthenticated]);

  // Initialize Pusher when userId is set
  useEffect(() => {
    if (userId) {
      console.log('Initializing Pusher');
      initializePusher();
    }
    return () => {
      cleanupPusher();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // Handle user sign-in
  const handleSignIn = () => {
    setIsAuthenticated(true);
  };

  // Handle user sign-out
  const handleSignOut = async () => {
    cleanupPusher();
    try {
      await axiosRequest(`${authURL}signout/`, 'post');
    } catch (error) {
      console.error('Error during sign out:', error);
    }
    setIsAuthenticated(false);
    setSection('SignIn');
    setSelectedCompany(null);
    setUserEmail('');
    setUserId('');
    setIsInProposalsGroup(false);
    setDefaultProjectName(''); // Reset default project name
    setDefaultProjectId('');
    // No need to remove idToken from cookies; backend handles token invalidation
  };

  // Effect to redirect to CompanyDetails if navigating to TechScouting while a company is selected
  useEffect(() => {
    if (section === 'TechScouting' && selectedCompany !== null) {
      setSection('CompanyDetails');
    }
  }, [section, selectedCompany]);

  const renderSection = () => {
    console.log('Current section:', section);

    if (isInProposalsGroup) {
      // Allowed sections for proposals group
      switch (section) {
        case 'Proposals':
          return <Proposals isDarkMode={isDarkMode} setSection={setSection} />;
        case 'Documents':
          return (
            <Library
              setSection={setSection}
              isDarkMode={isDarkMode}
              isInProposalsGroup={isInProposalsGroup}
              selectedProjectId={selectedProjectId}
              setSelectedProjectId={setSelectedProjectId}
            />
          );
        case 'Settings':
          return (
            <Settings
              userEmail={userEmail}
              handleSignOut={handleSignOut}
              isDarkMode={isDarkMode}
              toggleDarkMode={toggleDarkMode}
            />
          );
        default:
          return <Proposals isDarkMode={isDarkMode} setSection={setSection} />;
      }
    }

    switch (section) {
      case 'ATOAssistant':
        return <ATOAssistant setSection={setSection} isDarkMode={isDarkMode} />;
      case 'TechScouting':
        return <TechScouting isDarkMode={isDarkMode} setSection={setSection} />;
      case 'Proposals':
        return <Proposals isDarkMode={isDarkMode} setSection={setSection} />; // <-- Added case for Proposals
      case 'StakeholderIdentification':
        return <StakeholderIdentification isDarkMode={isDarkMode} />;
      case 'CompanyDetails':
        return <CompanyDetails isDarkMode={isDarkMode} setSection={setSection} />;
      case 'ProjectSupport':
        return <ProjectSupport isDarkMode={isDarkMode} />;
      case 'MOUGeneration':
        return <MOUGeneration isDarkMode={isDarkMode} />;
      case 'Documents':
        return (
          <Library
            setSection={setSection}
            isDarkMode={isDarkMode}
            isInProposalsGroup={isInProposalsGroup}
            selectedProjectId={selectedProjectId}
            setSelectedProjectId={setSelectedProjectId}
          />
        );
      case 'ATODocuments':
        return <ATODocuments setSection={setSection} isDarkMode={isDarkMode} />;
      case 'Settings':
        return (
          <Settings
            userEmail={userEmail}
            handleSignOut={handleSignOut}
            isDarkMode={isDarkMode}
            toggleDarkMode={toggleDarkMode}
          />
        );
      default:
        return <TechScouting isDarkMode={isDarkMode} setSection={setSection} />;
    }
  };

  // Conditional rendering for authentication
  if (!isAuthenticated) {
    return section === 'SignUp' ? (
      <SignUp onSignIn={handleSignIn} setSection={setSection} />
    ) : (
      <SignIn onSignIn={handleSignIn} setSection={setSection} />
    );
  }

  return (
    <div className="App">
      <a
        href="#main-content"
        className="skip-link"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            const mainContent = document.getElementById('main-content');
            if (mainContent) {
              mainContent.focus();
            }
          }
        }}
      >
        Skip to main content
      </a>
      <DefaultProjectContext.Provider
        value={{
          defaultProjectId,
          setDefaultProjectId,
          defaultProjectName,
          setDefaultProjectName,
        }}
      >
        <Navbar
          setSection={setSection}
          isInProposalsGroup={isInProposalsGroup}
          userEmail={userEmail}
          isDarkMode={isDarkMode}
        />
        <div className="content" id="main-content" tabIndex={-1}>
          <TechScoutingContext.Provider
            value={{ techResponse, setTechResponse, selectedCompany, setSelectedCompany }}
          >
            <StakeholderIdentificationContext.Provider
              value={{ stakeholderResponse, setStakeholderResponse }}
            >
              {renderSection()}
            </StakeholderIdentificationContext.Provider>
          </TechScoutingContext.Provider>
        </div>
        <SiteMap setSection={setSection} isInProposalsGroup={isInProposalsGroup} isDarkMode={isDarkMode} />
        <ToastContainer />
      </DefaultProjectContext.Provider>
    </div>
  );
};

export default App;

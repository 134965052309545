// src/components/Loading.tsx

import React, { useEffect } from 'react';
import './style/Loading.css';

const Loading: React.FC = () => {
  useEffect(() => {
    const loadingElement = document.getElementById('loading-status');
    if (loadingElement) {
      loadingElement.textContent = ''; // Reset content to trigger announcement
      setTimeout(() => {
        loadingElement.textContent = 'Loading';
      }, 0);
    }
  }, []);

  return (
    <div className="loading-container">
      <div className="loading-spinner">
        <div className="loading-inner-circle"></div>
        <div className="loading-outer-circle">
          <div className="loading-rotating-circle">
            {/* Mark the SVG as decorative by hiding it from assistive technologies */}
            <svg viewBox="0 0 60 60" aria-hidden="true" focusable="false">
              <circle className="loading-circle" cx="30" cy="30" r="28"></circle>
            </svg>
          </div>
        </div>
      </div>
      {/* Visually Hidden Loading Status for Screen Readers */}
      <div id="loading-status" className="sr-only" aria-live="assertive" role="status">
        Loading
      </div>
    </div>
  );
};

export default Loading;

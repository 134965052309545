// src/components/ProposalsLoading.tsx

import React, { useEffect, useState } from 'react';
import './style/ProposalsLoading.css';

interface LoadingProps {
  isDarkMode?: boolean;
  interval?: number; // Interval in milliseconds
}

const ProposalsLoading: React.FC<LoadingProps> = ({ isDarkMode, interval = 2000 }) => {
  const messages = [
    'Launching proposal evaluation agent',
    'Parsing proposal',
    'Chunking proposal',
    'Vectorizing text',
    'Launching market research agent',
    'Performing technical evaluation',
  ];

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [visible, setVisible] = useState(true); // New state to control visibility

  useEffect(() => {
    const loadingElement = document.getElementById('proposalsloading-status');
    if (loadingElement) {
      loadingElement.textContent = ''; // Reset content to trigger announcement
      setTimeout(() => {
        loadingElement.textContent = 'Loading';
      }, 0);
    }
  }, []);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (currentMessageIndex < messages.length - 1) {
      timer = setTimeout(() => {
        setCurrentMessageIndex(currentMessageIndex + 1);
      }, interval); // Use the interval prop
    } else {
      // Last message displayed, set a timeout to hide the component
      timer = setTimeout(() => {
        setVisible(false);
      }, interval);
    }

    return () => clearTimeout(timer); // Cleanup the timer
  }, [currentMessageIndex, messages.length, interval]);

  // If not visible, render nothing
  if (!visible) {
    return null;
  }

  return (
    <div className="proposalsloading-container">
      <div className="proposalsloading-spinner">
        <div className="proposalsloading-inner-circle"></div>
        <div className="proposalsloading-outer-circle">
          <div className="proposalsloading-rotating-circle">
            {/* Mark the SVG as decorative by hiding it from assistive technologies */}
            <svg viewBox="0 0 60 60" aria-hidden="true" focusable="false">
              <circle className="proposalsloading-circle" cx="30" cy="30" r="28"></circle>
            </svg>
          </div>
        </div>
      </div>
      {/* Visually Hidden Loading Status for Screen Readers */}
      <div id="proposalsloading-status" className="sr-only" aria-live="assertive" role="status">
        Loading
      </div>
      {/* Display current message */}
      <div
        className={`proposalsloading-message ${isDarkMode ? 'dark' : 'light'}`}
        aria-live="polite"
        role="status"
      >
        {messages[currentMessageIndex]}
      </div>
    </div>
  );
};

export default ProposalsLoading;
